import { initAbTesting } from "@solvari/common-fe/ab-testing";
import { initArgus } from "@solvari/common-fe/argus/service";
import { initFontAwesome } from "@solvari/common-fe/integrations";

import { initHeyflowForm } from "@/shared/components/heyflow-form/heyflowForm";

import "@/satellites/utils/sentry";
import "@/vue/utils/solvariConfig";
import "@solvari/common-fe/polyfills";

function main() {
  void initArgus();
  void initAbTesting();
  initFontAwesome();
  void initHeyflowForm();
}

main();
